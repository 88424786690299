/* VARIABLE config
====================== */

$black: #1E1E1E;
$gray: #323232;
$light-gray: #EEEEEE;
$main: #E60012;
$sub: #0087D3;

$base-lh: 1.8125;
$base-fs: 1.6rem;
$reset-fs: 12;

$font-base: source-han-sans-japanese, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$mincho: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$mincho-yu: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$marugo: "ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$font-gengo: source-han-sans-japanese,sans-serif;
$font-mont: montserrat,sans-serif;
