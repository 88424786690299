/* PRODUCTS page
====================== */
.products {
  @include base-wrap();
  padding-bottom: 100px;
  @include mq(sp) {
    padding-bottom: 50px;
  }
  /* Introductory text */
  .intro_text {
    @include base-wrap--space();
    padding: 48px 0 46px;
    font-size: 1.722rem;
    line-height: 1.75;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(12);
      padding: 23px 0 34px;
      letter-spacing: -.2px;
      text-align: left;
    }
  }
  /* page navigation */
  .pnav {
    background: #171717;
    margin-bottom: 85px;
    padding: 34px 34px 30px;
    @include mq(sp) {
      margin-bottom: 45px;
      padding: 24px 18px;
    }
    .list {
      @include clearfix();
      margin-bottom: 10px;
      li {
        float: left;
        width: 250px;
        margin-right: 10px;
        margin-bottom: 10px;
        @include mq(sp) {
          width: 49.25%;
          margin-right: 1.5%;
          margin-bottom: 6px;
        }
        &:nth-child(4n) {
          @include mq(pc) {
            margin-right: 0;
          }
        }
        &:nth-child(2n) {
          @include mq(sp) {
            margin-right: 0;
          }
        }
        a {
          position: relative;
          display: block;
          height: 87px;
          padding-top: 28px;
          background: #fff;
          font-size: 1.8rem;
          text-align: center;
          @include mq(sp) {
            @include fz_vw(13);
            height: 64px;
            padding-top: 14px;
            line-height: 1.1154;
          }
          &::after {
            position: absolute;
            left: 50%;
            bottom: 8px;
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-right: 2px solid $main;
            border-bottom: 2px solid $main;
            transform: rotate(45deg) translateX(-50%);
            transition: .2s;
            @include mq(sp) {
              bottom: 3px;
              width: 3px;
              height: 3px;
            }
          }
          &:hover {
            background: $main;
            color: #fff;
            &::after {
              border-color: #fff;
            }
          }
        }
        &.line01 {
          a {
            @include mq(sp) {
              padding-top: 25px;
            }
          }
        }
      }
    }
    .notice {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 300;
      @include mq(sp) {
        @include fz_vw(10);
      }
    }
  }
  .products_items {
    @include base-wrap--space();
    margin-bottom: 45px;
    .products_list {
      @include clearfix();
      li {
        float: left;
        width: 258px;
        margin-bottom: 28px;
        margin-right: 22px;
        @include mq(sp) {
          width: 49.25%;
          margin-right: 1.5%;
          margin-bottom: 14px;
        }
        &:nth-child(4n) {
          @include mq(pc) {
            margin-right: 0;
          }
        }
        &:nth-child(2n) {
          @include mq(sp) {
            margin-right: 0;
          }
        }
        &:nth-child(2n+1) {
          @include mq(sp) {
            clear: both;
          }
        }
        a {
          display: block;
          .image {
            position: relative;
            margin-bottom: 10px;
            border: 1px solid #A1A1A1;
            overflow: hidden;
            @include mq(sp) {
              margin-bottom: 10px;
              text-align: center;
              background: #fff;
            }
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background: rgba(230,0,18,.1);
              transition: .2s;
              transform: translateY(100%);
            }
            img {
              @include mq(sp) {
                width: auto;
                height: 114px;
              }
            }
          }
          .name {
            position: relative;
            padding-left: 25px;
            font-size: 1.7rem;
            font-weight: 500;
            @include mq(sp) {
              @include fz_vw(12);
              margin-bottom: 8px;
              padding-left: 18px;
              line-height: 1.2;
            }
            &::before {
              position: absolute;
              top: 5px;
              left: 0;
              content: "";
              display: block;
              width: 20px;
              height: 23px;
              background: url(../img/cmn/icn_arrow_05.svg) 0 0 no-repeat;
              background-size: 97% auto;
              @include mq(sp) {
                top: 1px;
                background-size: 13px 13px;
              }
            }
          }
          &:hover {
            .image {
              &::before {
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
}




