@function get_vw($size, $viewport:320){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

@mixin fs($font_size:10){
  font-size: $font_size * 1px;
  @include mq(sp) {
    font-size: $font_size * 1px;
    font-size: get_vw($font_size);
  }
}