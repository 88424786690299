/* COMMON page
====================== */
/* main image */
.mv {
  height: 205px;
  padding-top: 80px;
  background: url(../img/page/bg_page_title.jpg) center top no-repeat;
  background-size: cover;
  @include mq(sp) {
    height: 150px;
    padding-top: 60px;
    background: url(../img/sp/page/bg_page_title.jpg) no-repeat;
    background-size: cover;
  }
}

.breadcrumbs {
  border-bottom: 1px solid #EFEFEF;
  @include mq(sp) {
    display: none;
  }
  .inner {
    @include base-wrap--space();
    list-style: none;
    padding: 5px 0;
    li {
      display: inline-block;
      padding-right: 8px;
      margin-right: 8px;
      font-size: 1.1rem;
      line-height: 1.2;
      &:not(:last-child) {
        border-right: 1px solid $black;
      }
      a {
        &:hover {
          color: $main;
        }
      }
    }
  }
}

.content {
  @include base-wrap();
}

