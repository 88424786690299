/* COMMON layout
====================== */
.wrapper {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  padding-top: 104px;
  @include mq(sp) {
    min-width: auto;
    padding-top: 0;
  }
}

/* ページ下部バナー */
.btm_banner {
  @include base-wrap--space();
  padding: 75px 0 67px;
  @include mq(sp) {
    padding: 36px 0;
  }
  a {
    &:hover {
      animation: light .3s;
    }
  }
}
.wrapper_page {
  .btm_banner_wrap {
    background: #EEEEEE;
  }
  .btm_banner {
    padding: 65px 0 67px;
    @include mq(sp) {
      padding: 36px 0;
    }
  }
}
/* ページ下部リンク */
.btm_link {
  background: $main;
  .inner {
    @include base-wrap--space();
    padding: 60px 0;
    @include mq(sp) {
      padding: 40px 0 20px;
    }
  }
  .list {
    @include clearfix();
    li {
      width: 534px;
      @include mq(sp) {
        width: auto;
        margin-bottom: 16px;
      }
      &:nth-child(1) {
        float: left;
        @include mq(sp) {
          float: none;
        }
      }
      &:nth-child(2) {
        float: right;
        @include mq(sp) {
          float: none;
        }
      }
      a {
        position: relative;
        display: block;
        min-height: 140px;
        padding-top: 30px;
        border: 1px solid #fff;
        color: #fff;
        text-align: center;
        @include mq(sp) {
          padding-top: 22px;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.07);
          transition: .5s;
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 27px;
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg) translateY(-50%);
          @include mq(sp) {
            top: auto;
            bottom: 10px;
            right: 10px;
            width: 8px;
            height: 12px;
            border: 0;
            background: url(../img/sp/cmn/icn_arrow_01.svg) 0 0 no-repeat;
            background-size: 8px 12px;
            transform: rotate(0);
          }
        }
        &:hover {
          &::before {
            background: rgba(0,0,0,.3);
          }
          &::after {
            animation: .2s ease .01s normal hover_light;
          }
        }
        p {
          position: relative;
          z-index: 100;
        }
        .title {
          font-size: 2.7rem;
          @include mq(sp) {
            @include fz_vw(20);
            margin-bottom: 2px;
          }
        }
        .text {
          font-weight: 300;
          @include mq(sp) {
            @include fz_vw(12);
          }
        }
      }
    }
  }
}
