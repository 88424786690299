/* ANIMATION
====================== */

@keyframes zoom {
  50% {
    transform: scale(1.05);
  }
}
@keyframes horizontal {
    0% { transform:translateX( -3px); }
  100% { transform:translateX(  0px); }
}
@keyframes vertical {
    0% { transform:translateY(-20px); }
  100% { transform:translateY(  0px); }
}
@keyframes slowloop {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes leftSlideIn {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
/* animation: .8s ease 0s normal opening_bg; */
@-webkit-keyframes opening_bg {
  0% {opacity: 1;}
  20% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes opening_bg {
  0% {opacity: 1;}
  20% {opacity: 1;}
  100% {opacity: 0;}
}
/* animation: .8s ease 0s normal opening_logo; */
@-webkit-keyframes opening_logo {
  0% {width:300px;}
  100% {width:140%;}
}
@keyframes opening_logo {
  0% {width:300px;}
  100% {width:140%;}
}
/* animation: 1.4s ease 1s normal opening_txt both; */
@-webkit-keyframes opening_txt {
  0% {opacity: 0;}
  45% {opacity: 0;}
  55% {opacity: 1;}
  100% {opacity: 1;}
}
@keyframes opening_txt {
  0% {opacity: 0;}
  45% {opacity: 0;}
  55% {opacity: 1;}
  100% {opacity: 1;}
}
@-webkit-keyframes opening_txt02 {
  0% {opacity: 0;}
  85% {opacity: 0;}
  95% {opacity: 1;}
  100% {opacity: 1;}
}
@keyframes opening_txt02 {
  0% {opacity: 0;}
  75% {opacity: 0;}
  91% {opacity: 1;}
  100% {opacity: 1;}
}
/* animation: 1.4s ease 1s normal opening_bgtxt; */
@-webkit-keyframes opening_bgtxt {
  0% {width: 0;left: 0;}
  45% {width: 100%;left: 0;}
  55% {width: 100%;left:0;}
  100% {width: 0;left:100%;}
}
@keyframes opening_bgtxt {
  0% {width: 0;left: 0;}
  45% {width: 100%;left: 0;}
  55% {width: 100%;left:0;}
  100% {width: 0;left:100%;}
}
/* animation: .3s ease .05s normal hover_arrow; */
@-webkit-keyframes hover_arrow {
  0% {right: 100%;}
  100% {right:10px;}
}
@keyframes hover_arrow {
  0% {right: 100%;}
  100% {right:10px;}
}
/* animation: .2s ease .01s normal hover_light; */
@-webkit-keyframes hover_light {
  0% {opacity: 0;}
  70% {opacity: .5;}
  100% {opacity: 0;}
}
@keyframes hover_light {
  0% {opacity: 0;}
  70% {opacity: .5;}
  100% {opacity: 0;}
}
/* animation: zoom .3s; */
@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}
@keyframes jump {
  50% {
    transform: translateY(-2px);
  }
}
@keyframes light {
  50% {
    opacity: .5;
  }
}