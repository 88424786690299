/* HELPER class
====================== */
.f_mont_b {
  font-family: montserrat,sans-serif;
  font-weight: 700;
  font-style: normal;
}
.f_shs_b {
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.f_shs_m {
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 500;
  font-style: normal;
}
.f_shs_n {
  font-family: source-han-sans-japanese,sans-serif;
  font-weight: 300;
  font-style: normal;
}
.hidden {
	display: none !important;
	visibility: hidden;
}
.clearfix {
	@include clearfix;
}
.clear {
	clear: both;
}

.sp {
	@include mq(pc) {
		display: none !important;
	}
  @include mq(tab) {
    display: none !important;
  }
}
.sp-s {
	@include mq(pc) {
		display: none !important;
	}
	@include mq(tab) {
		display: none !important;
	}
	@include mq(sp) {
		display: block !important;
	}
}

.pc {
  @include mq(tab) {
    display: block !important;
  }
	@include mq(sp) {
		display: none !important;
	}
}
.pc-inline {
  @include mq(tab) {
    display: inline !important;
  }
  @include mq(sp) {
    display: none !important;
  }
}
.pc-s {
	@include mq(tab) {
		display: block !important;
	}
	@include mq(sp) {
		display: none !important;
	}
}

/* @group float
-------------------------------- */
.right {
  float: right;
}
.left {
  float: left;
}
.img-left {
  float: left;
  margin: 0 20px 10px 0;
}
.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}
.ta-center {
  text-align: center !important;
}
.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}
.va-bottom {
  vertical-align: bottom !important;
}
.va-middle {
  vertical-align: middle !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @status font color
-------------------------------- */
.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
	float: left;
	margin: 0 20px 10px 0;
}
.alignright {
	float: right;
	margin: 0 0 10px 20px;
}
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
