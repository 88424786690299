/* HEADER layout
====================== */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1100px;
  height: 104px;
  border-bottom: 2px solid $main;
  background: rgba(255,255,255,.8);
  z-index: 9999;
  @include mq(sp) {
    position: static;
    min-width: auto;
    height: auto;
  }
  .header_inner {
    @include base-wrap();
    position: relative;
  }
  .header_logo {
    padding-top: 20px;
    @include mq(sp) {
      position: static;
      width: 100%;
      height: auto;
      padding-top: 0;
    }
    a {
      display: block;
      @include mq(sp) {
        padding: 10px 4%;
      }
    }
    img {
      width: 167px;
      height: 31px;
      margin-right: 22px;
      @include mq(sp) {
        width: 31.56vw;
        height: 26px;
        margin-right: 10px;
      }
    }
    b {
      display: inline-block;
      color: #858585;
      font-size: 1.218rem;
      letter-spacing: 0.5px;
      transform: translateY(-4px);
      @include mq(sp) {
        @include fz_vw(9.5);
        transform: translateY(-1px);
      }
    }
  }
  .hnav {
    position: absolute;
    right: 0;
    top: 14px;
    @include mq(sp) {
      display: none;
    }
    .hnav_list {
      font-size: 0;
      li {
        display: inline-block;
        margin-left: 3px;
        a {
          position: relative;
          display: inline-block;
          width: 137px;
          height: 39px;
          color: #fff;
          background: $main;
          font-size: 1.2rem;
          text-align: center;
          line-height: 39px;
          &::after {
            position: absolute;
            top: 50%;
            right: 8px;
            content: "";
            display: block;
            width: 4px;
            height: 5px;
            background: url(../img/cmn/icn_arrow_01.svg) 0 0 no-repeat;
            background-size: 4px 5px;
            transform: translateY(-50%);
          }
          b {
            display: inline-block;
          }
          &:hover {
            animation: light .3s;
            b {
              animation: jump .3s;
            }
          }
        }
        &.shop {
          a {
            background: $black;
          }
        }
      }
    }
  }
  .gnav {
    padding-top: 8px;
    @include mq(sp) {
      display: none;
    }
    .gnav_list {
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        display: inline-block;
        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          content: "";
          display: block;
          width: 1px;
          height: 20px;
          background: #D3D3D3;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            content: "";
            display: block;
            width: 1px;
            height: 20px;
            background: #D3D3D3;
            transform: translateY(-50%);
          }
        }
        a {
          display: inline-block;
          width: 100%;
          padding: 10px 25px;
          font-size: 13px;
          text-align: center;
          &:hover {
            color: $main;
          }
        }
      }
    }
  }
}