/* TITLE parts
====================== */
/* toppage content title */
.title01 {
  line-height: 1;
  .ja {
    display: block;
    margin-bottom: 18px;
    font-size: 3rem;
    font-weight: 700;
    white-space: nowrap;
    @include mq(sp) {
      @include fz_vw(22.5)
      margin-bottom: 11px;
    }
  }
  .en {
    color: $main;
    font-family: $font-mont;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    @include mq(sp) {
      @include fz_vw(9);
    }
  }
}
/* page mv title */
.title02 {
  line-height: 1;
  .ja {
    display: block;
    margin-bottom: 18px;
    color: #fff;
    font-size: 2.7rem;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(19)
      margin-bottom: 11px;
    }
  }
  .en {
    display: block;
    color: $main;
    font-family: $font-mont;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(8);
    }
  }
}
/* page content title */
.title03 {
  margin-bottom: 50px;
  padding: 14px 22px;
  border-bottom: 2px solid $main;
  background: #EEEEEE;
  font-size: 2.5rem;
  @include mq(sp) {
    @include fz_vw(17.5)
    margin-bottom: 20px;
    padding: 6px 16px;
  }
}