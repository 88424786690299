/* BUTTON parts
====================== */
.btn01 {
  a {
    position: relative;
    display: inline-block;
    width: 466px;
    height: 74px;
    border-radius: 4px;
    background: $main;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 74px;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(14);
      width: 100%;
      height: 80px;
      line-height: 80px;
    }
    span {
      position: relative;
      z-index: 1000;
    }
    &::before {
      position: absolute;
      left: 50%;
      top: 0;
      content: "";
      display: block;
      width: 0;
      height: 100%;
      border-radius: 4px;
      background: $black;
      transition: .4s;
      z-index: 900;
    }
    &::after {
      position: absolute;
      right: 20px;
      top: 50%;
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url(../img/cmn/icn_arrow_02.svg) 0 0 no-repeat;
      background-size: 15px 15px;
      transform: translateY(-50%);
      z-index: 901;
    }
    &:hover {
      &::before {
        left: 0;
        width: 100%;
      }
    }
  }
}

/* animation: .2s ease .05s normal hover_button; */
@-webkit-keyframes hover_button {
  0% {right: 40%;}
  100% {right: 30px;}
}
@keyframes hover_button {
  0% {right: 40%;}
  100% {right: 30px;}
}

.btn02 {
  text-align: right;
  transform: translateY(-10px);
  a {
    display: inline-block;
    padding-right: 72px;
    font-family: $font-mont;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1px;
    background: url(../img/cmn/icn_arrow_03.svg) 80% center no-repeat;
    background-size: 36px 5px;
    &:hover {
      background-position: 100% center;
    }
  }
}

.btn03 {
  text-align: center;
  a {
    display: inline-block;
    width: 432px;
    height: 80px;
    border: 1px solid #C8C8C8;
    font-family: $font-mont;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1px;
    background: url(../img/cmn/icn_arrow_03.svg) 96% 47% no-repeat;
    background-size: 36px 5px;
    line-height: 80px;
    @include mq(sp) {
      width: 67.5vw;
      height: 48px;
      line-height: 46px;
    }
  }
}