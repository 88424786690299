/* TOP page
====================== */
.hero {
  .inner {
    width: 100%;
    height: 553px;
    background: url(../img/top/bg_mv.jpg) center no-repeat;
    background-size: cover;
    @include mq(sp) {
      height: auto;
      background: url(../img/sp/top/bg_mv.jpg) center no-repeat;
    }
  }
  .inner2 {
    height: 173px;
    background: $light-gray;
    @include mq(sp) {
      height: auto;
    }
  }
  .slider {
    width: 1214px;
    margin: 0 auto;
    padding-top: 65px;
    @include mq(sp) {
      width: 90%;
      padding: 20px 0;
    }
    .slide-item {
      img {
        width: 100%;
      }
    }
  }
  .thumbnail-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1058px;
    margin: 0 auto;
    padding-top: 29px;
    background: $light-gray;
    @include mq(sp) {
      width: auto;
      margin: 0 5%;
      padding: 18px 0 40px;
    }
    .thumbnail-item {
      position:relative;
      flex: 0 1 24.2%;
      margin-bottom: 10px;
      box-sizing: border-box;
      cursor: pointer;
      @include mq(sp) {
        flex: 0 1 49%;
        margin-bottom: 8px;
      }
      &:after {
        content:'';
        background-color: rgba(0,0,0,0.5);
        position:absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: .3s opacity linear;
      }
      &.thumbnail-current:after {
        opacity: 0;
      }
      img {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}
/* headline topics */
.top_news {
  @include base-wrap--space();
  padding: 28px 0;
  @include mq(sp) {
    padding: 20px 0;
  }
  .ttl_top_news {
    display: inline-block;
    margin-right: 40px;
    padding-left: 8px;
    padding-right: 42px;
    border-right: 2px solid $main;
    color: $main;
    font-family: $font-mont;
    font-size: 2.13rem;
    font-weight: 700;
    letter-spacing: 1.6px;
    line-height: 27px;
    @include mq(sp) {
      display: none;
    }
  }
  .headline {
    display: inline-block;
    .date {
      margin-right: 8px;
    }
    .category {
      display: inline-block;
      height: 20px;
      margin-right: 25px;
      padding: 0 4px;
      background: #3E3E3E;
      color: #fff;
      font-size: 1.3rem;
      line-height: 20px;
      transform: translateY(-1px);
      @include mq(sp) {
        @include fz_vw(9.5);
        height: 17px;
        line-height: 17px;
      }
    }
    .title {
      font-weight: 300;
      letter-spacing: -1.2px;
      @include mq(sp) {
        display: block;
      }
    }
  }
}

/* information */
.top_info {
  background: $light-gray;
  .inner {
    @include base-wrap--space();
    @include clearfix();
    padding: 70px 0 75px;
    @include mq(sp) {
      padding: 23px 0 39px;
    }
  }
  .ttl_top_info {
    margin-bottom: 20px;
    font-family: $font-mont;
    font-size: 2.3rem;
    font-weight: 700;
    letter-spacing: 2.5px;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(14.5);
      margin-bottom: 12px;
      letter-spacing: 1.5px;
    }
  }
  .schedule {
    float: left;
    width: 517px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
    .inner2 {
      margin-bottom: 10px;
      padding: 16px 60px;
      background: #fff;
      @include mq(sp) {
        margin-bottom: 2px;
        padding: 13px 16px 10px;
      }
    }
    .ymd {
      margin-left: 55px;
      font-size: 1.9rem;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(12.8);
        margin-left: 50px;
        margin-bottom: 14px;
        line-height: 1;
      }
      .month {
        margin: 0 20px;
        font-size: 4.3rem;
        @include mq(sp) {
          @include fz_vw(29);
          margin: 0 16px;
        }
      }
      .en {
        letter-spacing: 1px;
      }
    }
    table {
      width: 100%;
      margin-bottom: 18px;
      @include mq(sp) {
        margin-bottom: 16px;
      }
      tr {
        th {
          width: 14.28%;
          padding: 5px 0;
          border-top: 1px solid #A8A8A8;
          border-bottom: 1px solid #A8A8A8;
          font-size: 1.4rem;
          @include mq(sp) {
            @include fz_vw(10);
            padding: 3px 0;
          }
          &:first-child {
            color: $main;
          }
          &:last-child {
            color: #0D5FA2;
          }
        }
        td {
          padding: 8px 0;
          text-align: center;
          @include mq(sp) {
            @include fz_vw(11.2);
            padding: 6px 0;
          }
          &:first-child {
            color: $main;
          }
          &:last-child {
            color: #0D5FA2;
          }
          a {
            display: inline-block;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: $main;
            color: #fff;
            line-height: 32px;
            @include mq(sp) {
              width: 28px;
              height: 28px;
              line-height: 26px;
            }
            &:hover {
              background: $black;
            }
          }
        }
        &:nth-child(2) {
          td {
            padding-top: 20px;
          }
        }
      }
    }
    .notice {
      padding-left: 10px;
      letter-spacing: .5px;
      font-weight: 300;
      @include mq(sp) {
        padding-left: 7px;
        padding-bottom: 25px;
      }
      small {
        @include mq(sp) {
          display: block;
        }
        &.notice_text {
          margin-right: 40px;
          @include mq(sp) {
            margin-right: 0;
          }
        }
      }
      span {
        margin-right: 5px;
        color: $main;
        font-size: 14px;
        @include mq(sp) {
          @include fz_vw(10);
        }
      }
    }
  }
  .pager {
    @include clearfix();
    margin: 0 -30px;
    @include mq(sp) {
      @include fz_vw(10);
      margin: 0;
      letter-spacing: 1px;
    }
    a {
      display: inline-block;
      &.prev {
        float: left;
      }
      &.next {
        float: right;
      }
      &:hover {
        color: $main;
      }
    }
  }
  .navigation {
    float: right;
    width: 550px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
    .list {
      li {
        margin-bottom: 8px;
        @include mq(sp) {
          margin-bottom: 6px;
        }
        a {
          position: relative;
          display: block;
          height: 143px;
          padding: 22px 10px 0 50px;
          background: #fff;
          @include mq(sp) {
            height: auto;
            padding: 12px 10px 10px 22px;
          }
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            display: block;
            width: 0;
            height: 100%;
            background: #FCE5E7;
            transition: .2s;
          }
          &::after {
            position: absolute;
            right: 10px;
            bottom: 10px;
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 8px;
            border-color: transparent transparent transparent #878787;
            @include mq(sp) {
              right: 7px;
              bottom: 9px;
              border-width: 3px 0 3px 5px;
            }
          }
          &:hover {
            &::before {
              width: 100%;
            }
          }
          div, p {
            position: relative;
            z-index: 100;
          }
          .image {
            float: left;
            padding-top: 22px;
            width: 74px;
          }
          .purpose {
            position: relative;
            width: 354px;
            height: 68px;
            margin-bottom: 12px;
            margin-left: 105px;
            border-radius: 34px;
            background: $main;
            color: #fff;
            line-height: 1.33;
            text-align: center;
            @include mq(sp) {
              @include fz_vw(11.5);
              width: 57.5vw;
              height: 55px;
              margin-bottom: 4px;
              margin-left: 22vw;
            }
            p {
              padding-top: 12px;
            }
            &::after {
              position: absolute;
              bottom: -6px;
              left: 50%;
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 10px 0 10px;
              border-color: $main transparent transparent transparent;
              transform: translateX(-50%);
            }
          }
          .link_text {
            margin-left: 90px;
            text-align: center;
            @include mq(sp) {
              @include fz_vw(10);
              margin-left: 21vw;
              font-weight: 300;
            }
          }
        }
        &.line01 {
          .purpose {
            p {
              padding-top: 24px;
              @include mq(sp) {
                padding-top: 18px;
              }
            }
          }
        }
        &:nth-child(1) {
          .image {
            @include mq(sp) {
              width: 16vw;
              padding-top: 18px;
            }
          }
        }
        &:nth-child(2) {
          .image {
            @include mq(sp) {
              width: 13vw;
              padding-top: 15px;
              transform: translateX(5px);
            }
          }
        }
        &:nth-child(3) {
          .image {
            @include mq(sp) {
              width: 13.5vw;
              padding-top: 12px;
              transform: translateX(5px);
            }
          }
        }
      }
    }
  }
}

/* logitics solution */
.top_logitics {
  @include base-wrap();
  padding: 100px 0 80px;
  @include mq(sp) {
    padding: 64px 0 60px;
  }
  .title01 {
    margin-bottom: 50px;
    @include mq(sp) {
      margin: 0 5% 32px;
    }
  }
  .trouble {
    margin-bottom: 36px;
    padding: 20px 20px 24px;
    border: 1px solid $main;
    @include mq(sp) {
      margin: 0 5% 20px;
      padding: 11px 11px 20px;
    }
    .ttl_trouble {
      margin-bottom: 16px;
      padding: 11px 0;
      background: #FCE5E7;
      color: $main;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(12.5);
        margin-bottom: 10px;
        padding: 3px 0;
      }
    }
    .list {
      @include clearfix();
      margin-left: 90px;
      @include mq(sp) {
        margin-left: 15px;
      }
      li {
        position: relative;
        float: left;
        width: 262px;
        margin-bottom: 4px;
        padding-left: 25px;
        font-size: 1.8rem;
        font-weight: 500;
        @include mq(sp) {
          @include fz_vw(11.5);
          padding-left: 18px;
          margin-bottom: 0;
        }
        &::before {
          position: absolute;
          left: 0;
          top: 10px;
          content: "";
          display: block;
          width: 16px;
          height: 14px;
          border: 1px solid $black;
          @include mq(sp) {
            top: 8px;
            width: 8px;
            height: 8px;
          }
        }
        &::after {
          position: absolute;
          left: 7px;
          top: 8px;
          content: "";
          display: block;
          width: 6px;
          height: 12px;
          border-right: 2px solid $main;
          border-bottom: 2px solid $main;
          transform: rotate(50deg);
          @include mq(sp) {
            left: 5px;
            top: 8px;
            width: 4px;
            height: 8px;
            border-width: 1px;
          }
        }
        &:nth-child(3n+1) {
          width: 262px;
        }
        &:nth-child(3n+2) {
          width: 272px;
        }
        &:nth-child(3n) {
          width: 360px;
        }
      }
    }
  }
  .image {
    margin-bottom: 40px;
    font-size: 0;
    @include mq(sp) {
      display: block;
      margin-bottom: 28px;
      font-size: 0;
    }
    img {
      width: 33%;
      @include mq(sp) {
        width: 49.5%;
      }
      &:not(:last-child) {
        margin-right: 0.5%;
        @include mq(sp) {
          margin-right: 1%;
        }
      }
    }
  }
  .the_body {
    @include clearfix();
    @include mq(sp) {
      margin: 0 5%;
    }
    .text {
      float: left;
      width: 577px;
      @include mq(sp) {
        float: none;
        width: auto;
        margin-bottom: 30px;
      }
    }
    .btn01 {
      float: right;
      width: 466px;
      @include mq(sp) {
        float: none;
        width: auto;
      }
    }
  }
}

/* products */
.top_products {
  background: $light-gray;
  .inner {
    @include base-wrap--space();
    padding: 108px 0 114px;
    @include mq(sp) {
      padding: 60px 0 76px;
    }
  }
  .top_products_head {
    @include clearfix();
    margin-bottom: 58px;
    @include mq(sp) {
      margin-bottom: 25px;
    }
  }
  .title01 {
    float: left;
    width: 150px;
    @include mq(sp) {
      float: none;
      width: auto;
      margin-bottom: 26px;
    }
  }
  .description {
    margin-left: 195px;
    font-weight: 300;
    @include mq(sp) {
      margin-left: 0;
    }
  }
  .top_products_box {
    @include clearfix();
    margin-bottom: 40px;
    padding: 40px 90px 30px;
    background: #fff;
    @include mq(sp) {
      margin-bottom: 30px;
      padding: 24px 20px 20px;
    }
    .image {
      float: right;
      width: 300px;
      @include mq(sp) {
        float: none;
        width: auto;
        text-align: center;
      }
      img {
        @include mq(sp) {
          width: 46vw;
        }
      }
    }
    .text {
      margin-top: 30px;
      margin-right: 420px;
      margin-bottom: 36px;
      font-weight: 300;
      @include mq(sp) {
        margin-top: 15px;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
  .ttl_notice {
    position: relative;
    margin-bottom: 10px;
    padding-left: 1em;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -1px;
    @include mq(sp) {
      @include fz_vw(14);
      margin-top: 45px;
      line-height: 1.357;
    }
    &::before {
      position: absolute;
      left: 0;
      top: 12px;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 8px 0 8px;
      border-color: $main transparent transparent transparent;
      @include mq(sp) {
        top: 7px;
        border-width: 9px 5px 0 5px;
      }
    }
  }
  .used_list {
    @include flex();
    li {
      display: inline-block;
      width: 32%;
      @include mq(sp) {
        display: block;
        width: auto;
      }
      .image {
        position: relative;
        margin-bottom: 10px;
        overflow: hidden;
        @include mq(sp) {
          margin-bottom: 5px;
          text-align: center;
          background: #fff;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(230,0,18,.1);
          transition: .2s;
          transform: translateY(100%);
        }
        img {
          @include mq(sp) {
            width: auto;
            height: 114px;
          }
        }
      }
      .title {
        position: relative;
        padding-left: 25px;
        font-size: 2rem;
        font-weight: 500;
        @include mq(sp) {
          @include fz_vw(14);
          margin-bottom: 8px;
          padding-left: 20px;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          content: "";
          display: block;
          width: 20px;
          height: 23px;
          background: url(../img/cmn/icn_arrow_05.svg) 0 0 no-repeat;
          background-size: 97% auto;
          transform: translateY(-40%);
          @include mq(sp) {
            top: 8px;
            background-size: 15px 15px;
          }
        }
      }
      a {
        display: block;
        &:hover {
          .image {
            &::before {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}

.separate_image {
  overflow: hidden;
  img {
    position: relative;
    left: 50%;
    max-width: none;
    height: 282px;
    transform: translateX(-50%);
    @include mq(sp) {
      max-width: 100%;
      height: auto;
    }
  }
}

/* reason */
.top_reason {
  @include base-wrap--space();
  padding: 158px 0 105px;
  @include mq(sp) {
    padding: 82px 0 60px;
  }
  .top_reason_head {
    @include clearfix();
    margin-bottom: 74px;
    @include mq(sp) {
      margin-bottom: 16px;
    }
  }
  .title01 {
    float: left;
    width: 200px;
    @include mq(sp) {
      float: none;
      width: auto;
      margin-bottom: 26px;
    }
    .ja {
      letter-spacing: 1.5px;
    }
    .en {
      margin-left: 5px;
    }
  }
  .description {
    margin-left: 250px;
    @include mq(sp) {
      margin-left: 0;
    }
  }
  .list {
    @include flex();
    margin-bottom: 56px;
    @include mq(sp) {
      margin-bottom: 27px;
    }
    li {
      position: relative;
      width: 348px;
      padding: 42px 0 36px;
      border: 1px solid #505050;
      list-style: none;
      line-height: 1.318;
      text-align: center;
      @include mq(sp) {
        width: auto;
        margin-bottom: 8px;
        padding: 48px 0 47px;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 58px 58px 0 0;
        border-color: $main transparent transparent transparent;
        @include mq(sp) {
          border-width: 50px 50px 0 0;
        }
      }
      &::after {
        position: absolute;
        top: 6px;
        left: 8px;
        color: #fff;
        @include mq(sp) {
          top: 5px;
          left: 7px;
        }
      }
      .title {
        margin-bottom: 32px;
        color: $main;
        font-size: 2.2rem;
        font-weight: 700;
        @include mq(sp) {
          @include fz_vw(16.5);
          margin-bottom: 0;
          transform: translateX(-35px);
        }
      }
      .image {
        @include mq(sp) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:nth-child(1) {
        &::after {
          content: "01";
        }
        .image {
          @include mq(sp) {
            right: 32px;
            width: 13.75vw;
          }
        }
      }
      &:nth-child(2) {
        &::after {
          content: "02";
        }
        .image {
          @include mq(sp) {
            right: 28px;
            width: 16.34vw;
          }
        }
      }
      &:nth-child(3) {
        padding-top: 30px;
        @include mq(sp) {
          padding: 25px 0 20px;
        }
        &::after {
          content: "03";
        }
        .title {
          margin-bottom: 30px;
          @include mq(sp) {
            margin-bottom: 0;
          }
        }
        .image {
          @include mq(sp) {
            right: 22px;
            width: 17.65vw;
          }
        }
      }
    }
  }
  .btn01 {
    text-align: center;
    a {
      width: 654px;
      @include mq(sp) {
        width: 100%;
      }
    }
  }
}

/* topics */
.top_topics {
  background: $light-gray;
  .inner {
    @include base-wrap--space();
    padding: 95px 0 85px;
    @include mq(sp) {
      position: relative;
      padding: 80px 0 64px;
    }
  }
  .top_topics_head {
    @include clearfix();
  }
  .title01 {
    float: left;
    width: 150px;
    @include mq(sp) {
      float: none;
      width: auto;
      margin-bottom: 27px;
    }
    .ja {
      font-size: 3.3rem;
      letter-spacing: 1.5px;
      @include mq(sp) {
        @include fz_vw(22.5);
        margin-bottom: 14px;
      }
    }
    .en {
      margin-left: 2px;
      font-size: 1.5rem;
      @include mq(sp) {
        @include fz_vw(9);
      }
    }
  }
  .top_topics_sns {
    margin-left: 180px;
    font-size: 0;
    transform: translateY(-3px);
    @include mq(sp) {
      position: absolute;
      top: 22px;
      right: 7px;
      margin-left: 0;
    }
    li {
      display: inline-block;
      width: 44px;
      margin-right: 10px;
      @include mq(sp) {
        width: 50px;
        height: 50px;
        margin-right: 17px;
      }
      &:last-child {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      a {
        display: block;
        img {
          width: 44px;
          height: 44px;
          @include mq(sp) {
            width: 50px;
            height: 50px;
          }
        }
        &:hover {
          transform: translateY(-4px);
        }
      }
    }
  }
  .btn02 {
    margin-bottom: 20px;
  }
  .list {
    border-top: 1px solid #555555;
    border-bottom: 1px solid #555555;
    @include mq(sp) {
      margin-bottom: 32px;
    }
    li {
      border-bottom: 1px solid #B3B3B3;
      a {
        position: relative;
        display: block;
        padding: 22px 10px;
        line-height: 1.5;
        @include mq(sp) {
          padding: 17px 2px 18px;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: "";
          display: block;
          background: rgba(230, 0, 18, 0.1);
          opacity: 0;
          transition: .4s;
        }
        &:hover {
          &::before {
            opacity: 1;
          }
        }
        span {
          position: relative;
          z-index: 100;
        }
        .date {
          display: inline-block;
          width: 162px;
          margin-right: 8px;
          font-weight: 500;
          letter-spacing: -1px;
          @include mq(sp) {
            width: 142px;
            margin-right: 0;
          }
        }
        .category {
          margin: 0 2px;
          padding: 3px 5px;
          background: #575757;
          color: #fff;
          font-size: 1.3rem;
          @include mq(sp) {
            @include fz_vw(9.5);
            padding: 1px 4px;
            white-space: nowrap;
          }
        }
        .cate01 {
          background: #6883A5;
        }
        .cate02 {
          background: #575757;
        }
        .title {
          margin-left: 15px;
          @include mq(sp) {
            display: block;
            margin-left: 0;
            padding-top: 8px;
          }
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}


