/* BASE setting
====================== */
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}
body {
  width: 100%;
  height: 100%;
	margin: 0;
	padding: 0;
  font-family: $font-gengo;
  font-size: $base-fs;
	font-style: normal;
	font-weight: normal;
	color: $black;
	-webkit-text-size-adjust: none;
	/* -webkit-font-smoothing: antialiased; */
  line-height: $base-lh;
  overflow-x: hidden;
  @include mq(sp) {
    @include fz_vw($reset-fs);
  }
}
a {
	color: $black;
  text-decoration: none;
  transition: .2s;
  &:hover {
    transition: .2s;
  }
  &:visited {
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.main {
  a:hover img {
    @include mq(pc) {
    }
  }
}
