/* SP NAVI layout
====================== */

/* スマホグローバルナビ開閉ボタン */
.spnav {
  position: relative;
  z-index: 9998;
  display: none;
  @include mq(sp) {
    display: block;
  }
  .header_logo {
    @include mq(sp) {
      width: 100%;
      margin-bottom: 32px;
    }
    a {
      display: block;
      @include mq(sp) {
        padding: 10px 4%;
      }
    }
    img {
      @include mq(sp) {
        width: 31.56vw;
        height: 26px;
        margin-right: 10px;
      }
    }
    b {
      @include mq(sp) {
        @include fz_vw(9.5);
        display: inline-block;
        color: #858585;
        letter-spacing: 0.5px;
        transform: translateY(-1px);
      }
    }
  }
  .spnav_menu {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9998;
    width: 100%;
    height: 55px;
    background: $main;
    color: #fff;
    li {
      float: left;
      display: inline-block;
      width: 28%;
      height: 100%;
      border-right: 1px solid #fff;
      a {
        display: block;
        height: 100%;
        padding-top: 18px;
        color: #fff;
        font-weight: bold;
        font-size: 13.13px;
        line-height: 1.185;
        text-align: center;
        vertical-align: top;
        img {
          vertical-align: middle;
        }
      }
      &:first-child {
        background: $black;
      }
      &:last-child {
        width: 15.9%;
        border-right: 0;
        background: $black;
        a {
          padding-top: 33px;
          font-size: 10px;
        }
      }
    }
  }
  .spnav_wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9997;
    display: none;
    width: 100%;
  }
  .spnav_inner {
    position: relative;
    height: 100vh;
    padding-bottom: 54px;
    background: #fff;
    overflow: scroll;
  }
  .spnav_title {
    @include fz_vw(9.5);
    margin-bottom: 7px;
    color: #989898;
    font-family: $font-mont;
    text-align: center;
  }
  .spnav_main {
    border-top: 1px solid #ADADAD;
    li {
      border-bottom: 1px solid #ADADAD;
      a {
        @include fz_vw(14.5);
        position: relative;
        display: block;
        padding: 14px 34px;
        font-weight: bold;
        &::after {
          position: absolute;
          top: 50%;
          right: 28px;
          content: "";
          display: block;
          width: 7px;
          height: 7px;
          border-top: 1px solid #2D2D2D;
          border-right: 1px solid #2D2D2D;
          transform: rotate(45deg) translateY(-50%);
        }
      }
      ul {
        li {
          border-bottom: none;
          a {
            @include fz_vw(12.5);
            display: block;
            padding: 16px 50px;
            background: #EEEEEE;
            color: #393939;
          }
        }
      }
    }
  }
  .spnav_sub {
    padding: 22px 28px 140px;
    li {
      a {
        position: relative;
        display: block;
        padding: 5px 0;
        &::before {
          content: "-";
        }
        &.link {
          span {
            position: relative;
            display: inline-block;
            &::after {
              position: absolute;
              right: -19px;
              top: 50%;
              content: "";
              display: block;
              width: 13px;
              height: 12px;
              background: url(../img/sp/cmn/icn_link.svg) 0 0 no-repeat;
              background-size: 13px 12px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

.panel-btn {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 55px;
  text-decoration: none;
  text-align: center;
  @include box-sizing(border-box);
}
.panel-btn .close {
  background: transparent;
}
.panel-btn .close::before, .panel-btn .close::after {
  margin-top: -2px;
  background: #fff;
}
.panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
}
.panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
}
.panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 2px;
  margin-top: -8px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
  transform: translate(-50%,-50%);
}
.panel-btn-icon::before, .panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 17px;
  height: 2px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}
.panel-btn-icon::before {
  margin-top: -7px;
}
.panel-btn-icon::after {
  margin-top: 5px;
}

.header_btn-open {
  position: absolute;
  top: 8px;
  right: 8px;
  .panel-btn {
    width: 30px;
    height: 30px;
  }
  .panel-btn-icon {
    margin-top: 0;
    background: #5C5C5C;
  }
  .panel-btn-icon::before, .panel-btn-icon::after {
    background: #5C5C5C;
  }
  .panel-btn .close {
    background: transparent;
  }
}