@mixin flex($wrap:nowrap){
  display: flex;
  justify-content: space-between;
  flex-wrap: $wrap;
  @include mq(sp) {
    display: block;
    width: auto;
  }
}
@mixin flex-sp($wrap:nowrap){
  display: flex;
  justify-content: space-between;
  flex-wrap: $wrap;
}