/* FOOTER layout
====================== */
.footer {
  background: #1E1E1E;
  color: #fff;
  .footer_inner {
    @include base-wrap--space();
    position: relative;
    padding: 68px 0 30px;
    @include mq(sp) {
      padding: 73px 0 100px;
    }
  }
  .footer_logo {
    margin-bottom: 30px;
    padding-bottom: 45px;
    border-bottom: 1px solid #838383;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 35px;
      padding-bottom: 0;
      border-bottom: 0;
    }
    img {
      width: 194px;
      height: 70px;
      @include mq(sp) {
        width: 55vw;
        height: 100px;
      }
    }
  }
  .footer_sns {
    position: absolute;
    top: 38px;
    right: 0;
    @include mq(sp) {
      position: static;
      margin-bottom: 21px;
      padding-bottom: 32px;
      border-bottom: 1px solid #838383;
      text-align: center;
    }
    .list {
      @include mq(sp) {
        font-size: 0;
      }
      li {
        display: inline-block;
        width: 44px;
        margin-left: 10px;
        @include mq(sp) {
          width: 50px;
          margin: 0 8px;
        }
        a {
          display: block;
          img {
            width: 44px;
            height: 44px;
            @include mq(sp) {
              width: 50px;
              height: 50px;
            }
          }
          &:hover {
            transform: translateY(-4px);
          }
        }
      }
    }
  }
  .footer_wrap {
    @include clearfix();
  }
  .footer_info {
    float: left;
    width: 370px;
    padding-left: 5px;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: .6px;
    @include mq(sp) {
      @include fz_vw(11.5);
      float: none;
      width: auto;
      margin: 0 4.5% 37px;
      padding-bottom: 47px;
      border-bottom: 1px solid #838383;
      text-align: center;
    }
    address {
      font-style: normal;
    }
    .address {
      margin-right: 5px;
      @include mq(sp) {
        margin-right: 0;
        letter-spacing: .08em;
      }
    }
    .map {
      @include mq(sp) {
        margin-right: 0;
        margin-bottom: 18px;
        display: block;
        color: #fff;
        font-weight: 300;
      }
      img {
        display: inline-block !important;
        width: 45px;
        height: 14px;
        vertical-align: middle;
        @include mq(sp) {
          display: none !important;
        }
      }
    }
    .tel {
      @include mq(sp) {
        width: 75.78vw;
        margin: 0 auto 4px;
        padding: 3px 0;
        border: 1px solid #fff;
      }
      .tel_title {
        margin-right: 6px;
        font-size: 15.72px;
        font-weight: 500;
        @include mq(sp) {
          @include fz_vw(11.5);
          margin-right: 2px;
        }
      }
      .tel_num {
        font-size: 2.2rem;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
    .opening_hours {
      margin-bottom: 21px;
      @include mq(sp) {
        margin-bottom: 14px;
      }
    }
    .button_footer {
      a {
        display: inline-block;
        width: 179px;
        height: 29px;
        padding-bottom: 1px;
        background: #CBCBCB;
        text-align: center;
        line-height: 28px;
        @include mq(sp) {
          width: 75.78vw;
          height: 45px;
          line-height: 45px;
        }
        &:hover {
          background: #fff;
        }
      }
      @include mq(sp) {
        @include fz_vw(11.5);
      }
    }
  }
}
.fnav {
  @include mq(sp) {
    display: none;
  }
  a {
    color: #fff;
    font-weight: 500;
    &:hover {
      color: $main;
    }
    &::before {
      content: "-";
    }
  }
  & > ul {
    float: left;
    padding-left: 20px;
    line-height: 1.5;
    & > li {
      margin-bottom: 8px;
      font-size: 1.3rem;
      &.link {
        position: relative;
        span {
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            right: -19px;
            top: 50%;
            content: "";
            display: block;
            width: 13px;
            height: 12px;
            background: url(../img/sp/cmn/icn_link.svg) 0 0 no-repeat;
            background-size: 13px 12px;
            transform: translateY(-50%);
          }
        }
      }
      ul {
        margin-top: 10px;
        li {
          margin-left: 10px;
          margin-bottom: 5px;
          color: #B2B2B2;
          font-size: 1.1rem;
          line-height: 1.5;
        }
      }
    }
  }
  .fnav01 {
    width: 220px;
  }
  .fnav02 {
    width: 170px;
    ul {
      margin-top: 22px;
      li {
        margin-left: 0;
      }
    }
  }
}
.footer_banner {
  float: right;
  width: 240px;
  @include mq(sp) {
    float: none;
    width: 78.44vw;
    margin: 0 auto;
  }
  p {
    margin-bottom: 12px;
    a {
      position: relative;
      display: block;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0;
      }
      &:hover {
        &::before {
          animation: light .3s;
        }
      }
    }
    &.iso {
      text-align: center;
      a {
        display: inline-block;
        width: 60px;
        margin: 0 7px;
        @include mq(sp) {
          width: 20.47vw;
          margin: 5px 7px;
        }
      }
    }
  }
}
.copyright {
  margin-top: 80px;
  color: #B2B2B2;
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: .06em;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(8);
    margin-top: 27px;
  }
}